import CloseIcon from 'assets/svg/closeIcon';
import React, { useEffect } from 'react';
import './ModalDialogue.styles.scss';

const ModalDialogue = ({
  children,
  icon,
  title,
  description,
  isModalOpen,
  onClose,
  width,
  topSpacing,
}) => {
  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add('overflow-y-hidden');
    } else {
      document.body.classList.remove('overflow-y-hidden');
    }
    return () => {
      document.body.classList.remove('overflow-y-hidden');
    };
  }, [isModalOpen]);
  return (
    <React.Fragment>
      {isModalOpen && (
        <div
          className={`w-[100vw] h-[100vh] bg-black-400 bg-opacity-30 fixed top-0 left-0 z-[999] flex justify-center overflow-y-auto ${
            !isModalOpen && 'hidden'
          }`}
        >
          <div
            style={{ width: width ? width : '400px' }}
            className={` bg-white rounded-xl p-6 opacity-100 ${
              topSpacing ? topSpacing : 'my-20'
            } modalAnimate h-fit`}
            data-state={isModalOpen}
          >
            <div className="flex items-start justify-between">
              <div>{icon || title}</div>
              <div className="cursor-pointer" onClick={onClose}>
                <CloseIcon />
              </div>
            </div>
            <div>
              <h5 className="text-lg font-semibold mt-3 text-gray-900">
                {!icon ? '' : title}
              </h5>
              <p className="text-gray-600 font-light mt-1">{description}</p>
            </div>
            <div className="">{children}</div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ModalDialogue;
