import { ResponsiveChoroplethCanvas } from '@nivo/geo';
import worldGeoJson from '../GeoChart/data/worldGeoJson.json';

const Choropleth = ({ data }) => {
  const values = data.map((item) => item?.value);
  const minValue = Math.min(...values);
  const maxValue = Math.max(...values);
  return (
    <ResponsiveChoroplethCanvas
      data={data}
      features={worldGeoJson.features}
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      colors={'YlGn'}
      unknownColor="#cdcdcd"
      domain={[minValue, maxValue]}
      label="properties.name"
      valueFormat=".2s"
      projectionScale={100}
      projectionTranslation={[0.5, 0.5]}
      projectionRotation={[0, 0, 0]}
      enableGraticule={false}
      borderWidth={0.2}
      borderColor="#252525"
      legends={[
        {
          anchor: 'bottom-left',
          direction: 'column',
          justify: true,
          translateX: 10,
          translateY: -20,
          itemsSpacing: 0,
          itemWidth: 80,
          itemHeight: 14,
          itemDirection: 'left-to-right',
          itemTextColor: '#444444',
          itemOpacity: 0.85,
          symbolSize: 10,
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000000',
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};

export default Choropleth;
