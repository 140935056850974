import VerticalEclipse from 'assets/svg/verticalEclipse';
import { formatDate } from 'pages/recognition/orderHistory/helper';
import { useGetVersionHistory } from 'query/rangeBuilder/externalData/externalData';
import React from 'react';

export const renderHistoryCard = (currData, prevData) => {
  return (
    <div>
      {Object.keys(currData)?.map((key, ind) => {
        return (
          <p className="text-gray-500 flex items-center gap-1" key={ind}>
            <span>{key}:</span>
            <span className="text-gray-900">{currData?.[key]}</span>
            <span className="line-through">{prevData?.[key]}</span>
          </p>
        );
      })}
    </div>
  );
};

const VersionHistory = ({ mappingRes, rangeFormTypeId, rangeDataId }) => {
  const { data: historyRes } = useGetVersionHistory(
    rangeFormTypeId,
    rangeDataId,
  );

  const transformKeyWithMapping = (data) => {
    const updatedData = {};
    Object.keys(data)?.forEach((key) => {
      if (mappingRes?.[key]) {
        const newKey = mappingRes?.[key]?.name;
        updatedData[newKey] = data?.[key];
      } else {
        updatedData[key] = data?.[key];
      }
    });
    return updatedData;
  };
  return (
    <div className="space-y-3">
      {historyRes?.length > 1 &&
        historyRes?.map((historyData, i) => {
          if (i < historyRes?.length - 1) {
            const currData = transformKeyWithMapping(historyData?.uploadedJson);
            const prevData = transformKeyWithMapping(
              historyRes?.[i + 1]?.uploadedJson,
            );
            return (
              <div
                key={i}
                className="flex justify-between border border-gray-200 rounded-xl p-4"
              >
                <div className="space-y-4">
                  <p className="text-sm font-semibold text-gray-900">
                    {formatDate(historyData?.createdDate)}
                  </p>
                  <div className="space-y-1">
                    {renderHistoryCard(currData, prevData)}
                  </div>
                </div>
                <VerticalEclipse className="w-5 h-5" />
              </div>
            );
          } else {
            return null;
          }
        })}
      {historyRes?.length <= 1}
      {<div className="font-normal text-base">No history to show.</div>}
    </div>
  );
};

export default VersionHistory;
