export const filterInitialState = {
  minPoints: 100,
  maxPoints: 10000,
  fitToMyBudget: false,
  walletId: [],
  categoriesId: [],
  brandsId: [],
};

export const loadingStates = {
  FETCHING_CART: 'FETCHING_CART',
  ADD_TO_CART: 'ADD_TO_CART',
  REMOVE_FROM_CART: 'REMOVE_FROM_CART',
  INCREASE_QUANTITY: 'INCREASE_QUANTITY',
  DECREASE_QUANTITY: 'DECREASE_QUANTITY',
};

export const status = {
  SUCCESS: 'SUCCESS',
  PENDING: 'PENDING',
  NO_WALLET: 'NO_WALLET',
  INSUFFICIENT_BALANCE: 'INSUFFICIENT_BALANCE',
  ERROR: 'ERROR',
};
