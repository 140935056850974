const TableIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.333}
      d="M1 5h12M5 1v12M4.2 1h5.6c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874C13 2.52 13 3.08 13 4.2v5.6c0 1.12 0 1.68-.218 2.108a2 2 0 0 1-.874.874C11.48 13 10.92 13 9.8 13H4.2c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C1 11.48 1 10.92 1 9.8V4.2c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874C2.52 1 3.08 1 4.2 1Z"
    />
  </svg>
);
export default TableIcon;
