import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline';
import ClockCheckIcon from 'assets/svg/ClockCheckIcon';
import Toggle from 'components/atoms/FormElements/toggle/Toggle';
import moment from 'moment';
import {
  useGetReversonHistory,
  useGetVersionHistory,
} from 'query/dashboard/ManageDashboard';
import React, { useEffect, useRef, useState } from 'react';

const VersionHistory = ({
  setVersionModalOpen,
  selectedFileId,
  dashboardId,
}) => {
  const [openHistory, setOpenHistory] = useState(false);
  const [selectedHistory, setSelectedHistory] = useState(null);
  const toggleRef = useRef(null);
  const [selectedVersionId, setSelectedVersionId] = useState(null);

  const { isLoading: versionLoading } = useGetReversonHistory(
    dashboardId,
    selectedVersionId,
  );

  const {
    data: historyData,
    isLoading,
    isError,
  } = useGetVersionHistory(selectedFileId, dashboardId);

  // useEffect(() => {
  //   const handleOutsideClick = (e) => {
  //     if (!toggleRef.current.contains(e.target)) {
  //       setSelectedHistory(null);
  //     }
  //   };

  //   document.addEventListener('mousedown', handleOutsideClick);
  //   return () => {
  //     document.removeEventListener('mousedown', handleOutsideClick);
  //   };
  // }, []);

  return (
    <div className="flex flex-col gap-3.5">
      <div className="flex justify-between items-center">
        <span className="text-gray-900 font-semibold text-xl">
          Version History
        </span>
        <XMarkIcon
          className="w-5 h-5 cursor-pointer"
          onClick={() => setVersionModalOpen(false)}
        />
      </div>
      <hr className="w-full h-1"></hr>
      <div className="pt-2">
        <Toggle
          label="Show Changes"
          checked={openHistory}
          onChange={() => setOpenHistory(!openHistory)}
          disabled={false}
          ref={toggleRef}
        />
      </div>
      <div className="flex flex-col gap-2">
        {Object.entries(historyData?.data || {})?.map((history, index) => (
          <div className="border border-gray-300 rounded-lg" key={index}>
            <Disclosure>
              <Disclosure.Button className="group px-5 py-3 flex w-full items-center justify-between">
                <div className="flex flex-col gap-1">
                  <span className="text-gray-900 text-sm font-normal">
                    {moment(history[0]).format('MMM DD YYYY')}
                  </span>
                  {/* <span className="text-gray-900 text-sm font-semibold -ml-5">
                    User Name
                  </span> */}
                </div>
                <ChevronDownIcon className="w-5 group-data-[open]:rotate-180" />
              </Disclosure.Button>
              <Disclosure.Panel className="flex flex-col gap-2 mt-2">
                {history[1]?.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => setSelectedHistory(item?.id)}
                    className={`flex justify-between items-center ${
                      selectedHistory === item?.id
                        ? 'bg-white border shadow-md rounded-lg px-3 py-2 m-2'
                        : 'px-5 py-3'
                    }`}
                  >
                    <div className="flex flex-col gap-1">
                      <span className="text-gray-600 text-xs ">
                        {moment(item?.createdDate).format(
                          'MMM DD YYYY HH:mm:ss',
                        )}
                      </span>
                      <span className="text-gray-900 font-semibold text-sm">
                        {item?.createdByName}
                      </span>
                    </div>
                    {selectedHistory === item?.id ? (
                      <div
                        className="cursor-pointer"
                        onClick={(e) => {
                          setSelectedVersionId(item?.id);
                          setSelectedHistory(null);
                        }}
                      >
                        <ClockCheckIcon />
                      </div>
                    ) : null}
                  </div>
                ))}
              </Disclosure.Panel>
            </Disclosure>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VersionHistory;
