import { DEBOUNCE_SEARCH_TIME } from 'apiClient/api.constant';
import Button from 'components/atoms/button/Button';
import ChipsLabels from 'components/atoms/chips-labels/ChipsLabels';
import Table from 'components/atoms/table/Table';
import TableHeader from 'components/atoms/tableHeader/TableHeader';
import { useDebounce } from 'hooks/useDebounce';
import { Search } from 'pages/benefits/manageBenefits/Search';
import {
  useGetEmployeesData,
  useGetEmployeesDataByCategory,
  useGetEmployeesDataFilter,
} from 'query/rangeBuilder/employees/employees';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import EmployeeSalaryGraph from '../finalRange/finalRangePopUp/EmployeeSalaryGraph';
import BenchmarkFilter from './benchmarkFilter/BenchmarkFilter';
import { Loader } from 'components/atoms/loader/Loader';
import TableSkeleton from 'components/atoms/skeletonLoaderTypes/TableSkeleton';
import Pagination from 'components/atoms/pagination/Pagination';
import Badge from 'components/atoms/badge/Badge';
const buttonTabs = [
  {
    id: '1',
    label: 'All',
    value: undefined,
  },
  {
    id: '2',
    label: 'Matched',
    value: 'matched',
  },
  {
    id: '3',
    label: 'Un Matched',
    value: 'unmatched',
  },
];

const Employees = () => {
  const { id: planId } = useParams();
  const [selectedTab, setSelectedTab] = useState(buttonTabs?.[0]);
  const [searchedValue, setSearchedValue] = useState('');
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedBenchmarks, setSelectedBenchmarks] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const debouncedValue = useDebounce(searchedValue, DEBOUNCE_SEARCH_TIME);
  const {
    data: employeeData,
    isLoading: isEmployeesDataWithoutCategoryLoading,
  } = useGetEmployeesData(
    planId,
    10,
    currentPage,
    selectedTab?.value,
    debouncedValue,
  );
  const { data: filterResData, isLoading: isFiltersLoading } =
    useGetEmployeesDataFilter(planId, selectedBenchmarks?.value);
  const {
    data: employeeDataByCategory,
    isLoading: isEmployeesDataWithCategoryLoading,
  } = useGetEmployeesDataByCategory(
    planId,
    selectedBenchmarks?.value,
    10,
    currentPage,
    selectedFilter,
    debouncedValue,
  );
  useEffect(() => {
    const benchmarks = employeeData?.benchmarks?.[0];
    setSelectedBenchmarks({
      label: benchmarks?.columnName,
      value: benchmarks?.id,
    });
  }, [employeeData]);

  const resColumns = employeeData?.headers?.map((header) => {
    return {
      id: header?.id,
      Header: (
        <TableHeader name={header?.columnName} HeaderKey={header?.columnName} />
      ),
      Cell: ({ row }) => (
        <>
          {header?.columnName === 'Employee Details' ? (
            <div className="flex items-center gap-3">
              <div className="flex flex-col flex-nowrap">
                <p className="font-semibold text-sm text-gray-900 flex text-nowrap">
                  {row.original?.uploadedJson?.Name}
                </p>
                <p className="text-sm text-gray-500 text-nowrap">
                  {`Emp ID: ${row.original?.uploadedJson?.['Employee Id']}`}
                </p>
              </div>
            </div>
          ) : (
            <span className="">
              <p className="text-sm text-gray-600">
                {row.original?.uploadedJson?.[header?.id]}
              </p>
            </span>
          )}
        </>
      ),
    };
  });

  const columns = [
    {
      id: '5',
      Header: (
        <TableHeader name="Compensation" HeaderKey="compensation" sort={true} />
      ),
      Cell: ({ row }) => (
        <span className="">
          <p className="text-sm text-gray-600">{`${
            row.original?.uploadedJson?.[selectedBenchmarks?.value]
          }`}</p>
        </span>
      ),
    },
    {
      id: '6',
      Header: (
        <TableHeader
          name="Internal Range"
          HeaderKey="internalRange"
          sort={true}
        />
      ),
      Cell: ({ row }) => {
        const rangeData = {};
        Object.keys(row?.original?.calculationJson)?.forEach((key) => {
          if (key?.includes(selectedBenchmarks?.value)) {
            rangeData[key?.split('_')?.[0]?.toLowerCase()] = parseInt(
              row?.original?.calculationJson?.[key],
            );
          }
        });
        return (
          <div className="flex flex-col gap-2">
            <EmployeeSalaryGraph
              data={{
                ...rangeData,
              }}
              averageSalary={rangeData?.mid}
            />
            <div className="flex justify-between">
              <p className="font-figtree text-xs font-medium text-gray-600">
                {rangeData?.min}
              </p>
              <p className="font-figtree text-xs font-medium text-gray-600">
                {rangeData?.mid}
              </p>
              <p className="font-figtree text-xs font-medium text-gray-600">
                {rangeData?.max}
              </p>
            </div>
          </div>
        );
      },
    },
    {
      id: '7',
      Header: (
        <TableHeader name="Status" HeaderKey="internalRange" sort={true} />
      ),
      Cell: ({ row }) => (
        <div>
          <ChipsLabels
            label={row.original.status}
            status={
              row.original?.status === 'Matched'
                ? 'accepted'
                : row.original?.status === 'Unmatched'
                ? 'rejected'
                : 'InProgress'
            }
            className="text-sm font-medium"
          />
        </div>
      ),
    },
  ];
  const onPageChanged = (event) => {
    setCurrentPage(event?.selected);
  };
  const totalCount = filterResData?.employees?.reduce(
    (total, item) => total + item?.count,
    0,
  );

  return (
    <div className="">
      <div className="flex flex-col gap-4">
        <div className="flex px-8 items-center justify-between">
          <div className="flex items-center gap-2">
            {buttonTabs?.map((tab) => {
              return (
                <Button
                  key={tab?.id}
                  variant={selectedTab?.label === tab?.label ? 'filled' : ''}
                  onClick={() => setSelectedTab(tab)}
                >
                  {tab?.label}
                </Button>
              );
            })}
          </div>
          <div className="flex items-center gap-4">
            <Search
              searchedValue={searchedValue}
              setSearchedValue={setSearchedValue}
              className="!py-2 !px-[14px]"
            />
          </div>
        </div>
        {isFiltersLoading || isEmployeesDataWithoutCategoryLoading ? (
          <div
            className="flex w-full items-center justify-center"
            style={{
              height: 'calc(100vh - 195px)',
            }}
          >
            <Loader />
          </div>
        ) : (
          <>
            <div className="border-y bg-gray-50 border-gray-200 px-8 py-1 flex items-center justify-between">
              <BenchmarkFilter
                benchmarkList={employeeData?.benchmarks?.map((benchmark) => {
                  return {
                    label: benchmark?.columnName,
                    value: benchmark?.id,
                  };
                })}
                selectedBenchmark={selectedBenchmarks}
                setSelectedBenchmark={setSelectedBenchmarks}
              />
            </div>

            <div className="flex w-full px-8 gap-4 cursor-pointer">
              {filterResData?.employees
                ?.map((item) => {
                  return {
                    label: item?.category,
                    value: item?.category,
                    ...item,
                  };
                })
                ?.map((tabData, index) => {
                  return (
                    <div
                      key={index}
                      className={`flex w-full gap-2.5 border shadow-md rounded-xl py-4 px-5  ${
                        selectedFilter === tabData?.value
                          ? 'border-partition'
                          : 'border-gray-300'
                      }`}
                      onClick={() => {
                        setSelectedFilter(
                          selectedFilter === tabData?.value
                            ? null
                            : tabData?.value,
                        );
                      }}
                    >
                      <div className="flex w-full flex-col gap-2.5">
                        <p className="font-figtree font-semibold text-xl">
                          {tabData?.count}
                        </p>
                        <p className="font-figtree text-sm font-normal text-gray-600">
                          {tabData?.label}
                        </p>
                      </div>
                      <div className="h-12">
                        <Badge
                          text={`${(tabData?.count / totalCount) * 100}%`}
                          backgroundColor="#EAECF0"
                          className="font-semibold text-xl"
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="px-8">
              {isEmployeesDataWithCategoryLoading ? (
                <TableSkeleton count={6} />
              ) : (
                <>
                  <Table
                    columns={[...(resColumns ?? []), ...columns]}
                    data={
                      selectedFilter
                        ? employeeDataByCategory?.employees ?? []
                        : employeeData?.employees ?? []
                    }
                  />
                  <Pagination
                    onPageChanged={onPageChanged}
                    pageCount={
                      (selectedFilter
                        ? employeeDataByCategory?.totalCount
                        : employeeData?.totalCount) / 10
                    }
                    currentPage={currentPage}
                  />
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Employees;
