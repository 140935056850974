import DatePicker from 'components/atoms/FormElements/datepicker/DatePicker';
import { Input } from 'components/atoms/FormElements/input/Input';
import Select from 'components/atoms/FormElements/select/Select';
import { useEffect, useState } from 'react';
import DropDownWithEmployeSearch from '../dropDowns/dropDownWithEmployeSearch/DropDownWithEmployeSearch';

export default function ValueEditor(props) {
  const {
    field,
    operator,
    value,
    valueSource,
    handleOnChange,
    fields,
    valueOptions,
  } = props;

  const [newValue, setNewValue] = useState(value);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  //console.log('props', props);

  useEffect(() => {
    if (props?.operator?.code === 'bw' || props?.operator?.code === 'nbw') {
      const [start, end] = value || [];
      setStartDate(start);
      setEndDate(end);
    } else setNewValue(value);
  }, [value]);

  const handleDatePickerChange = (date) => {
    const [start, end] = date || [];
    setStartDate(start);
    setEndDate(end);
    start && end && handleOnChange([start, end]);
  };

  const formatFields = (data) => {
    return data.map((field) => ({
      ...field,
      label: field?.alias || field?.value,
      name: field?.id,
      value: field?.id,
    }));
  };
  const getFormattedList = (list) => {
    return (
      list &&
      list?.map((option) => {
        return {
          value: option?.id || option?.code,
          label:
            option?.value ||
            option?.helpText ||
            option?.description ||
            option?.displayName,
          ...option,
        };
      })
    );
  };

  const formatValueOptions = (data) => {
    return data.map((field) => ({
      ...field,
      label: field?.alias || field?.value,
      value: field?.id,
    }));
  };

  const options = valueOptions[field?.label] || [];

  if (valueSource === 'field') {
    return (
      <Select
        value={
          newValue?.label
            ? { label: newValue?.label, value: newValue?.id }
            : null
        }
        options={formatFields(fields)}
        placeholder="Select Value"
        className=" disabled:cursor-not-allowed"
        isDisabled={
          props?.field?.value && props?.operator?.value ? false : true
        }
        onChange={(e) => handleOnChange(e)}
      />
    );
  } else if (options?.length > 0) {
    return (
      <Select
        value={
          newValue?.label
            ? { label: newValue?.label, value: newValue?.id }
            : null
        }
        options={formatValueOptions(options)}
        placeholder="Select Value"
        className=" disabled:cursor-not-allowed"
        isDisabled={
          props?.field?.value && props?.operator?.value ? false : true
        }
        onChange={(e) => handleOnChange(e)}
      />
    );
  } else if (
    props?.field?.dataType === 'Date' &&
    (props?.operator?.code === 'bw' || props?.operator?.code === 'nbw')
  ) {
    return (
      <DatePicker
        label=""
        selectsRange
        onChange={handleDatePickerChange}
        placeholder="Select a date range"
        isDisabled={
          props?.field?.value && props?.operator?.value ? false : true
        }
        startDate={startDate ? new Date(startDate) : null}
        endDate={endDate ? new Date(endDate) : null}
        dateFormat="MMMM d, yyyy"
      />
    );
  } else if (
    props?.operator?.code === 'bw' ||
    props?.operator?.code === 'nbw'
  ) {
    <div className="flex justify-center items-center">
      <Input
        type="number"
        value={startDate}
        placeholder="Enter start value"
        className="bg-white disabled:bg-[#f2f2f2] border-[rgb(209 213 219)] placeholder:text-[#808080] !h-[38px]"
        disabled={props?.field?.value && props?.operator?.value ? false : true}
        onBlur={() => {
          const newData = [startDate, endDate];
          startDate?.length > 0 && handleOnChange(newData);
        }}
        onChange={(e) => setStartDate(e.target.value)}
      />
      <Input
        type="text"
        value={endDate}
        placeholder="Enter end value"
        className="bg-white disabled:bg-[#f2f2f2] border-[rgb(209 213 219)] placeholder:text-[#808080] !h-[38px]"
        disabled={props?.field?.value && props?.operator?.value ? false : true}
        onBlur={() => {
          const newData = [startDate, endDate];
          endDate?.length > 0 && handleOnChange(newData);
        }}
        onChange={(e) => setEndDate(e.target.value)}
      />
    </div>;
  } else if (props?.field?.dataType === 'User') {
    return (
      <div className="w-full">
        <DropDownWithEmployeSearch
          isMulti={false}
          members={props.value}
          setMembers={(e) => {
            handleOnChange(e);
          }}
        />
      </div>
    );
  } else {
    return (
      <Input
        type="text"
        value={newValue}
        placeholder="Enter value"
        className="bg-white disabled:bg-[#f2f2f2] border-[rgb(209 213 219)] placeholder:text-[#808080] !h-[38px]"
        disabled={props?.field?.value && props?.operator?.value ? false : true}
        onBlur={() => handleOnChange(newValue)}
        onChange={(e) => setNewValue(e.target.value)}
      />
    );
  }
}
//   const CustomValueEditor = (props) => {
//     const { field, operator, value, valueSource, handleOnChange } = props;

//     const fieldData = fields.find((f) => f.id === field);
//     const options = valueOptions[field] || [];
//     //    console.log('customValueEditor:', props);
//     if (fieldData?.dataType === 'multioption') {
//       //   return (
//       //     <select value={value} onChange={(e) => handleOnChange(e.target.value)}>
//       //       {options.map((opt) => (
//       //         <option key={opt.value} value={opt.value}>
//       //           {opt.label}
//       //         </option>
//       //       ))}
//       //     </select>
//       //   );
//     }

//     // if (fieldData?.dataType === 'date') {
//     //   return (
//     //     <input
//     //       type="date"
//     //       value={value}
//     //       onChange={(e) => handleOnChange(e.target.value)}
//     //     />
//     //   );
//     // }

//     // return (
//     //   <input
//     //     type="text"
//     //     value={value}
//     //     onChange={(e) => handleOnChange(e.target.value)}
//     //   />
//     // );

//     if (valueSource === 'field') {
//       return (
//         <Select
//           value={{ label: value?.label, value: value?.id }}
//           options={formatFields(fields)}
//           onChange={(e) => handleOnChange(e)}
//         />
//       );
//     } else if (options?.length > 0) {
//       return (
//         <Select
//           value={{ label: value?.label, value: value?.id }}
//           options={formatValueOptions(options)}
//           onChange={(e) => handleOnChange(e)}
//         />
//         // <select value={value} onChange={(e) => handleOnChange(e.target.value)}>
//         //   {options.map((opt) => (
//         //     <option key={opt.value} value={opt.value}>
//         //       {opt.label}
//         //     </option>
//         //   ))}
//         // </select>
//       );
//     } else if (props.operator === 'bw' || props.operator === 'nbw') {
//       return (
//         <DatePicker
//           label=""
//           selectsRange
//           onChange={(date) => {
//             const [start, end] = date || [];
//             //    setStartDate(start);
//             //    setEndDate(end);
//             //start && end && handleEditNode(id, 'condition3', { value: date });
//             handleOnChange([
//               start,
//               'Sat Aug 28 2024 00:00:00 GMT+0530 (India Standard Time)',
//             ]);
//           }}
//           startDate={value?.length ? new Date(value[0]) : null}
//           endDate={
//             value?.length
//               ? new Date(
//                   'Sat Aug 28 2024 00:00:00 GMT+0530 (India Standard Time)',
//                 )
//               : null
//           }
//           dateFormat="MMMM d, yyyy"
//         />
//       );
//     } else {
//       return (
//         <input
//           type="text"
//           value={value}
//           onChange={(e) => handleOnChange(e.target.value)}
//         />
//       );
//     }
//   };
