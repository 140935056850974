import './utils/setupGlobal';
export * as FormulaCalculator from './importFiles';

// import { atomicFormula } from './atomic';
// import { batchFormula } from './batch';
// import * as formulajs from '@formulajs/formulajs';

// (async () => {
//     try {
//         // const atomicResult = await atomicFormula({
//         //     dependencyValueMap: {
//         //         a: 5
//         //     },
//         //     formulaString: 'SUM(["a"],["b"])'
//         // });
//         // console.log(atomicResult);
//         // const batchResult = await batchFormula({
//         //     dependencyValueMap: {
//         //         a: 5,
//         //         b: 10,
//         //         c: 20,
//         //         d: 30
//         //     },
//         //     formulaMap: {
//         //         three: {
//         //             formulaString: 'SUM(["one"],["two"])'
//         //         },
//         //         one: {
//         //             formulaString: 'SUM(["three"],["b"])'
//         //         },
//         //         two: { formulaString: 'SUM(["c"],["d"])' }
//         //     }
//         // });
//         // console.log(batchResult);
//         // const x = formulajs.VLOOKUP(
//         //     'abc',
//         //     [
//         //         ['a', 2],
//         //         ['aa', 3],
//         //         ['ab', 4]
//         //     ],
//         //     1,
//         //     true
//         // );
//         const atomicResult = await atomicFormula({
//             formulaString: 'VLOOKUP("KT", ["a"])',
//             dependencyValueMap: {
//                 a: [
//                     ['KT', 'Key talent'],
//                     ['LL', 'Leader in Line'],
//                     ['AR', 'At Risk'],
//                     ['UP', 'Up for promotion']
//                 ]
//             }
//         });

//         console.log(atomicResult);
//     } catch (error) {
//         console.error(error);
//     }
// })();
