import { AxiosError } from 'axios';
import * as CONSTANT from '../api.constant';
import { get, patch, post } from '../apiCalls';

export const createBenefit = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/benefit/create-benefit`,
      payload,
    );
    return response;
  } catch (error) {
    console.log(error, 'Error while creating benefit');
  }
};

export const publishBenefit = async (benefitId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/benefit/update-benefit-publish-state/${benefitId}?status=PUBLISH`,
    );
    return response;
  } catch (error) {
    console.log(error, 'Error while publishing benefit');
  }
};

export const createEligibilityLimit = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/benefit/create-benefit-eligibility-limit`,
      payload,
    );
    return response;
  } catch (error) {
    console.log(error, 'Error while creating benefit eligibility limit');
  }
};
export const createEligibilityUser = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/benefit/create-benefit-eligibility-rule`,
      payload,
    );
    return response;
  } catch (error) {
    console.log(error, 'Error while creating benefit eligibility user');
  }
};
export const getEligibleUserCount = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/benefit/get-eligibility-group-count`,
      payload,
    );
    return {
      data: response?.data?.eligibleUsersData,
      total: response?.data?.pagination?.totalCount,
    };
  } catch (error) {
    console.log(error, 'Error while getting user count');
  }
};
export const getAllBenefits = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/benefit/get-all-benefit?status=PUBLISH`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! benefits fetch failed');
    }
  }
};

export const getCycleCodes = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/benefit/cycle-codes`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! benefits fetch failed');
    }
  }
};

export const getAllEligibleBenefits = async (
  pageSize,
  pageIndex,
  status,
  query,
  empId,
) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/benefit/${empId}?pageSize=${pageSize}&pageIndex=${pageIndex}&status=${status}&s=${query}`,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! benefits fetch failed');
    }
  }
};

export const getAllProgramsForBenefit = async (countryId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/program?countryId=${countryId}&archive=false`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! programs fetch failed');
    }
  }
};

export const getAllEligibleProgramsForBenefit = async (countryId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/program/eligible?countryId=${countryId}`,
    );
    return response.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! programs fetch failed');
    }
  }
};

export const createEnrolment = async (body, id) => {
  try {
    const response = id
      ? await updateEnrolmentAPI(body, id)
      : await post(
          `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/enrollment/create-benefit-enrollment`,
          body,
        );
    return response;
  } catch (error) {
    console.log(error, 'Error while creating enrolment');
  }
};

export const createClaim = async (body, id) => {
  try {
    const response = id
      ? await updateClaimAPI(body, id)
      : await post(
          `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/claim/create-benefit-claim`,
          body,
        );
    return response;
  } catch (error) {
    console.log(error, 'Error while creating enrolment');
  }
};

export const createEnrolmentForm = async (body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/enrollment-form/create-benefit-enrollment-form`,
      body,
    );
    return response;
  } catch (error) {
    console.log(error, 'Error while creating enrolment form');
  }
};

export const createClaimForm = async (body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/claim-form/create-benefit-claim-form`,
      body,
    );
    return response;
  } catch (error) {
    console.log(error, 'Error while creating enrolment form');
  }
};

export const getEnrollmentPeriod = async (frequency) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/enrollment/get-benefit-enrollment-period?period=${frequency}`,
    );
    return response;
  } catch (error) {
    console.log(error, 'Error while creating enrolment form');
  }
};

export const getClaimPeriod = async (frequency) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/claim/get-benefit-claim-period?period=${frequency}`,
    );
    return response;
  } catch (error) {
    console.log(error, 'Error while creating enrolment form');
  }
};

export const getFieldValues = async (id) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/budget/get-field-values`,
      { fieldId: id },
    );
    return response;
  } catch (error) {
    console.log(error, 'Error while creating enrolment form');
  }
};

export const fetchProgramWorkflows = async (pageIndex, pageSize) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_WORKFLOW_V2}/${CONSTANT.API_WORKFLOW}/get-all-workflows?view=inactive&category=Benefit`,
    );

    const resultData = response?.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! workflow fetch failed');
    }
  }
};

export const getBenefitById = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/benefit/get-benefit-format/${id}`,
    );
    return response.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! workflow fetch failed');
    }
  }
};

export const updateEnrolmentAPI = async (body, id) => {
  try {
    const response = await patch(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/enrollment/update-benefit-enrollment/${id}`,
      body,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const updateClaimAPI = async (body, id) => {
  try {
    const response = await patch(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/claim/update-benefit-claim/${id}`,
      body,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const updateClaimFormAPI = async (body, id) => {
  try {
    const response = await patch(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/claim-form/update-benefit-claim-form/${id}`,
      body,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const updateEnrolmentFormAPI = async (body, id) => {
  try {
    const response = await patch(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/enrollment-form/update-benefit-enrollment-form/${id}`,
      body,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const fetchFormFieldsAPI = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/benefit/form-fields/${id}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const getBenefitDropdown = async (countryId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/benefit/admin/get-benefit-dropdown/${countryId}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const getEligibleUserForBenefit = async (
  benefitId,
  status,
  pageIndex,
) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${
        CONSTANT.API_BENEFITS_V2
      }/benefit/admin/eligible-user-for-benefit/${benefitId}${
        status !== 'All'
          ? `?state=${status}&pageIndex=${pageIndex}&pageSize=10`
          : `?pageIndex=${pageIndex}&pageSize=10`
      }`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const getDependentsHeader = async (benefitId, status) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee-dependent/headers`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

export const getDependentsValues = async (benefitId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee-dependent/values/${benefitId}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error!');
    }
  }
};

/****************        CLAWBACK          ****************/

export const getEnrolmentStartDateDropdown = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/enrollment/get-clawback-start-date-dropdown/${id}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Dropdown fetch failed.');
    }
  }
};

export const getClaimStartDateDropdown = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/claim/get-clawback-start-date-dropdown/${id}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Dropdown fetch failed.');
    }
  }
};

export const updateClawbackDetailsEnrolment = async (id, body) => {
  try {
    const response = await patch(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/enrollment/update-clawback-details/${id}`,
      body,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Dropdown fetch failed.');
    }
  }
};

export const updateClawbackDetailsClaim = async (id, body) => {
  try {
    const response = await patch(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/claim/update-clawback-details/${id}`,
      body,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Dropdown fetch failed.');
    }
  }
};

/****************        RULES        ****************/

export const getAllRules = async (pageIndex) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/rules/get-rules?pageSize=10&pageIndex=${pageIndex}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Rules fetch failed.');
    }
  }
};

export const getRulesCategory = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/rules/get-all-rule-category`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Rules category fetch failed.');
    }
  }
};

export const getRestrcitionRules = async (categoryId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/rules/get-category-dropdown/${categoryId}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Restriction rules fetch failed.');
    }
  }
};

export const createRule = async (body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/rules/create-rule`,
      body,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Create rule failed.');
    }
  }
};

export const editRule = async (body, id) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/rules/edit-rule/${id}`,
      body,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Edit rule failed.');
    }
  }
};

export const getRuleById = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/rules/get-rule/${id}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Fetch rule failed.');
    }
  }
};

export const archiveRule = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/rules/archive-rule/${id}`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Archive rule failed.');
    }
  }
};

export const fetchAllBenefits = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/rules/get-all-benefits`,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Fetch benefits failed.');
    }
  }
};
