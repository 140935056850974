import { CheckBoxProgram } from 'components/atoms/FormElements/input/Input';

export const CompensationTypeFilter = ({
  typeList,
  selectedType,
  setSelectedType,
  type,
}) => {
  return (
    <div className="flex items-center gap-2">
      <p className="text-sm font-medium text-gray-600">Benchmarks:</p>
      <div className="flex items-center text-sm font-medium text-gray-600 gap-[2px]">
        {typeList?.map((compensationType) => {
          return (
            <CheckBoxProgram
              key={compensationType}
              type="checkbox"
              checked={
                type === 'singleSelect'
                  ? selectedType?.[0] === compensationType?.value
                  : selectedType?.includes(compensationType?.value)
              }
              label={compensationType?.name}
              onChange={() =>
                type === 'singleSelect'
                  ? setSelectedType([compensationType?.value])
                  : setSelectedType(
                      selectedType?.includes(compensationType?.value)
                        ? selectedType?.filter(
                            (type) => type !== compensationType?.value,
                          )
                        : [...selectedType, compensationType?.value],
                    )
              }
            />
          );
        })}
      </div>
    </div>
  );
};
