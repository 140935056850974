import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Colors,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from 'chart.js';
import { forwardRef } from 'react';
import {
  Bar,
  Bubble,
  Doughnut,
  Line,
  Pie,
  PolarArea,
  Radar,
  Scatter,
} from 'react-chartjs-2';

const Chart = forwardRef(
  (
    {
      type,
      data,
      chartOptions = {},
      pluginOptions = {},
      chartId,
      displayLegend = false,
      disableTooltip = false,
      plugins,
    },
    ref,
  ) => {
    ChartJS?.register(
      LineElement,
      CategoryScale,
      LinearScale,
      PointElement,
      BarElement,
      Tooltip,
      ArcElement,
      Filler,
      Colors,
      Legend,
    );

    const options = {
      plugins: {
        legend: {
          display: displayLegend,
        },
        tooltip: {
          mode: 'index',
          // intersect: false,
          enabled: !disableTooltip,
        },
        ...pluginOptions,
      },
      ...chartOptions,
      scales: {
        ...chartOptions?.scales,
      },
    };
    let ChartComponent;
    switch (type) {
      case 'line':
        ChartComponent = Line;
        break;
      case 'doughnut':
        ChartComponent = Doughnut;
        break;
      case 'pie':
        ChartComponent = Pie;
        break;
      case 'polarArea':
        ChartComponent = PolarArea;
        break;
      case 'radar':
        ChartComponent = Radar;
        break;
      case 'bubble':
        ChartComponent = Bubble;
        break;
      case 'scatter':
        ChartComponent = Scatter;
        break;
      default:
        ChartComponent = Bar;
    }
    return (
      <ChartComponent
        ref={ref}
        id={chartId}
        data={data}
        options={options}
        plugins={plugins}
      />
    );
  },
);

export default Chart;
