// eslint-disable-next-line @typescript-eslint/no-var-requires
// const formulajs = require('@formulajs/formulajs');

import * as formulajs from '@formulajs/formulajs';

const formulaMap = { ...formulajs };
const formulaList = Object.keys(formulaMap) as (keyof typeof formulaMap)[];

formulaList.forEach((formula) => {
    (global as any)[formula] = formulaMap[formula];
});

(global as any)['MASTER_VLOOKUP'] = (lookup_value: string, table_array: string[][]) => {
    return formulajs.VLOOKUP(lookup_value, table_array, 2, false);
};
(global as any)['COMP_VLOOKUP'] = (lookup_value: string, table_array: string[][]) => {
    return formulajs.VLOOKUP(lookup_value, table_array, 2, false);
};

// (global as any)['MASTER_MATCH'] = (lookup_value: string, table_array: string[][]) => {
//     return formulajs.MATCH(lookup_value, table_array, 1);
// };
// (global as any)['COMP_MATCH'] = (lookup_value: string, table_array: string[][]) => {
//     return formulajs.MATCH(lookup_value, table_array, 1);
// };

// (global as any)['MASTER_INDEX'] = (table_array: string[][], rowIndex:number) => {
//     return formulajs.INDEX(table_array, rowIndex, colIndex);
// };
// (global as any)['COMP_INDEX'] = (table_array: string[][]) => {
//     return formulajs.INDEX(table_array, rowIndex, colIndex);
// };
