import { Chart } from 'chart.js';
import BarGraph from 'components/molecules/graph/BarGraph';
import { useGetFinalRangesGraphData } from 'query/rangeBuilder/finalRange/finalRange';
import React, { useEffect, useRef, useState } from 'react';

const contractData = {
  data: [
    {
      'Pay Range Title': 'SYS1',
      jobTitle: 'Software Developer',
      min: 650000,
      max: 800000,
      mid: 700000,
      sourceData: [
        {
          company: 'Aon',
          min: 600000,
          max: 1000000,
          mid: 700000,
        },
        {
          company: 'Bon',
          min: 400000,
          max: 800000,
          mid: 600000,
        },
      ],
    },
    {
      'Pay Range Title': 'SYS2',
      jobTitle: 'Software Developer',
      min: 800000,
      max: 1600000,
      mid: 1300000,
      sourceData: [
        {
          company: 'Aon',
          min: 900000,
          max: 1200000,
          mid: 1000000,
        },
        {
          company: 'Bon',
          min: 700000,
          max: 1300000,
          mid: 1100000,
        },
      ],
    },
    {
      'Pay Range Title': 'SYS3',
      jobTitle: 'Software Developer',
      min: 350000,
      max: 550000,
      mid: 425000,
      sourceData: [
        {
          company: 'Aon',
          min: 1234,
          max: 1900,
          mid: 1500,
        },
        {
          company: 'Bon',
          min: 1400,
          max: 1900,
          mid: 1500,
        },
      ],
    },
    {
      'Pay Range Title': 'SYS4',
      jobTitle: 'Software Developer',
      min: 4800000,
      max: 6300000,
      mid: 5200000,
      sourceData: [
        {
          company: 'Aon',
          min: 1700,
          max: 2000,
          mid: 2400,
        },
        {
          company: 'Bon',
          min: 1900,
          max: 2000,
          mid: 2400,
        },
      ],
    },
    {
      'Pay Range Title': 'SYS5',
      jobTitle: 'Software Developer',
      min: 6300000,
      max: 8800000,
      mid: 7500000,
      sourceData: [
        {
          company: 'Aon',
          min: 2400,
          max: 3000,
          mid: 2700,
        },
        {
          company: 'Bon',
          min: 2200,
          max: 2700,
          mid: 2500,
        },
      ],
    },
  ],
};

const convertCompensationInUnit = (amount, unit) => {
  if (!unit) {
    return `${
      (amount / 1000).toFixed(1).endsWith('.0')
        ? (amount / 1000).toFixed(1)?.slice(0, -2)
        : (amount / 1000).toFixed(1)
    }`;
  } else {
    return `${
      (amount / 100000).toFixed(1).endsWith('.0')
        ? (amount / 100000).toFixed(1).slice(0, -2)
        : (amount / 100000).toFixed(1)
    }`;
  }
};

const contractDataToGraphDataMapper = (
  contractData,
  compensationUnit = false,
) => {
  return {
    labels: contractData?.data?.map((data) => data?.['Pay Range Title']),
    levels: [
      {
        label: '',
        data: contractData?.data?.map((data) => {
          return {
            x: [data?.min, data.max],
            y: data?.['Pay Range Title'],
            mid: data?.mid,
            sourceData: data?.sourceData?.map((data) => {
              return {
                ...data,
                compensationUnit: compensationUnit,
              };
            }),
            compensationUnit: compensationUnit,
            id: data?.id,
          };
        }),
        backgroundColor: 'hsla(216, 16%, 84%, 0.5)',
        borderColor: 'hsla(218, 15%, 65%, 0.5)',
        barThickness: 6,
        borderRadius: 2,
        borderWidth: 0.87,
      },
    ],
  };
};

const fetchMoreData = (currentData) => {
  const newData = [
    ...currentData.data,
    {
      'Pay Range Title': `SYS${currentData?.data?.length + 1}`,
      jobTitle: 'Software Developer',
      min: currentData?.data?.[currentData?.data?.length - 1]?.max,
      max: currentData?.data?.[currentData?.data?.length - 1]?.max + 500000,
      mid: currentData?.data?.[currentData?.data?.length - 1]?.max + 200000,
      sourceData: [
        {
          company: 'Aon',
          min: currentData?.data?.[currentData?.data?.length - 1]?.max - 100000,
          max: currentData?.data?.[currentData?.data?.length - 1]?.max + 600000,
          mid: currentData?.data?.[currentData?.data?.length - 1]?.max + 200000,
        },
        {
          company: 'Bon',
          min: currentData?.data?.[currentData?.data?.length - 1]?.max + 100000,
          max: currentData?.data?.[currentData?.data?.length - 1]?.max + 600000,
          mid: currentData?.data?.[currentData?.data?.length - 1]?.max + 200000,
        },
      ],
    },
  ];
  return {
    data: newData,
  };
};

const GraphicalView = ({
  apiData,
  refetchGraphData,
  isRefetching,
  selectedBenchmark,
  isLakhsCompUnit,
  query,
  pageSize,
  setPageSize,
}) => {
  const [data, setData] = useState(contractData);
  const graphRef = useRef(null);

  useEffect(() => {
    refetchGraphData();
  }, [pageSize, query]);

  useEffect(() => {
    const removeAndFilterIdWithSelectedBenchMark = (calculationObject) => {
      let barData = {};
      Object.keys(calculationObject)?.forEach((key) => {
        if (key?.includes(selectedBenchmark?.value)) {
          const newKey = key?.split('_')?.[0];
          barData[newKey.toLowerCase()] = calculationObject?.[key];
        }
      });
      return barData;
    };
    const data = apiData?.calculations?.map((calculation) => {
      const barData = removeAndFilterIdWithSelectedBenchMark(
        calculation?.finalCalc,
      );
      const sourceData = [];
      Object.keys(calculation?.calculations)?.forEach((key) => {
        const temp = removeAndFilterIdWithSelectedBenchMark(
          calculation?.calculations?.[key],
        );
        sourceData?.push({
          company: apiData?.externalMap?.[key],
          ...temp,
        });
      });

      return {
        id: calculation.id,
        ...barData,
        sourceData: sourceData,
        ...calculation?.pivotedJson,
      };
    });
    if (data) {
      setData({ data: data });
    }
  }, [apiData, selectedBenchmark]);

  useEffect(() => {
    const handleScroll = (e) => {
      if (graphRef.current) {
        const {
          scrollTop,
          scrollHeight,
          clientHeight,
          scrollLeft,
          scrollWidth,
          clientWidth,
        } = graphRef.current;

        // Check if user scrolled to bottom or right edge
        if (
          !isRefetching &&
          scrollTop + clientHeight + 4 >= scrollHeight &&
          pageSize <= apiData?.totalCount
        ) {
          setPageSize(pageSize + 5);
        }
      }
    };

    const graphEl = graphRef.current;
    if (graphEl) {
      graphEl.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (graphEl) {
        graphEl.removeEventListener('scroll', handleScroll);
      }
    };
  }, [data, isLakhsCompUnit, isRefetching]);

  const bulletPointPlugin = {
    id: 'bulletPointPlugin',
    afterDatasetsDraw(chart) {
      const {
        ctx,
        data: graphData,
        scales: { x, y },
      } = chart;
      const meta = chart.getDatasetMeta(0);

      meta.data.forEach((bar, index) => {
        const barData = graphData.datasets[0].data[index];
        const barWidth = bar.width;
        const barX = bar.x;

        // Calculate barStart and barEnd based on barX
        const barStart = barX - barWidth;
        const barEnd = barX;

        const midPosition =
          barStart +
          (barWidth * (barData?.mid - barData?.x?.[0])) /
            (barData?.x?.[1] - barData?.x?.[0]);

        ctx.save();
        const linearGradient = ctx.createLinearGradient(0, 0, 3, 9);

        // Add color stops to the gradient
        linearGradient.addColorStop(0, '#101828');
        linearGradient.addColorStop(1, '#475467');
        ctx.strokeStyle = linearGradient; // Customize line color
        ctx.lineWidth = 3; // Customize line width
        ctx.lineCap = 'round'; // Set line cap to round

        // Draw the line with rounded ends
        ctx.beginPath();
        ctx.moveTo(midPosition, bar.y - bar.height / 2 - 2);
        ctx.lineTo(midPosition, bar.y + bar.height / 2 + 2);
        ctx.stroke();
        ctx.restore();

        // Draw min and max values
        ctx.save();
        ctx.fillStyle = '#667085'; // Customize text color
        ctx.font = '400 9.5px Figtree'; // Customize text font
        ctx.textAlign = 'center';

        // Draw the min value
        const barLength = barEnd - barStart;

        let adjustLength = 0;
        if (barLength < 54 && !barData?.compensationUnit) {
          adjustLength = (54 - barLength) / 2;
        } else if (barLength < 40) {
          adjustLength = (40 - barLength) / 2;
        }

        ctx.fillText(
          convertCompensationInUnit(barData?.x?.[0], barData?.compensationUnit),
          barStart - 4 - adjustLength,
          bar.y - bar.height / 2 - 5,
        );
        ctx.fillText(
          convertCompensationInUnit(barData?.mid, barData?.compensationUnit),
          midPosition,
          bar.y - bar.height / 2 - 5,
        );

        // Draw the max value
        ctx.fillText(
          convertCompensationInUnit(barData?.x?.[1], barData?.compensationUnit),
          barEnd + 4 + adjustLength,
          bar.y - bar.height / 2 - 5,
        );
        ctx.restore();
      });
    },
  };

  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.style.background = 'white';
      tooltipEl.style.borderWidth = '1px';
      tooltipEl.style.borderColor = '#EAECF0';
      tooltipEl.style.borderRadius = '8px';
      tooltipEl.style.color = '#475467';
      tooltipEl.style.opacity = 1;
      tooltipEl.style.boxShadow =
        '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)';
      tooltipEl.style.padding = '8px';
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.transform = 'translate(0, 0)';
      tooltipEl.style.transition = 'all .1s ease';

      const titleDiv = document.createElement('div');
      titleDiv.style.width = '250px';
      titleDiv.style.height = '29px';
      titleDiv.style.padding = '2px 12px 2px 12px';
      titleDiv.style.display = 'flex';
      titleDiv.style.textAlign = 'center';
      titleDiv.style.color = '#92929D';

      titleDiv.style.background = '#F9F9F9';
      titleDiv.style.borderTopLeftRadius = '6px';
      titleDiv.style.borderTopRightRadius = '6px';
      const titleText = document.createTextNode('Benchmarks');
      titleDiv.appendChild(titleText);

      tooltipEl.appendChild(titleDiv);

      const canvas = document.createElement('canvas');
      canvas.width = 250;
      canvas.height = 115;
      tooltipEl.appendChild(canvas);

      tooltipEl.chartInstance = new Chart(canvas, {
        type: 'bar',
        data: {
          labels: ['Sample 1', 'Sample 2'],
          datasets: [
            {
              label: 'Sample Data',
              data: [10, 20],
              backgroundColor: 'hsla(216, 16%, 84%, 0.5)',
              borderColor: 'hsla(218, 15%, 65%, 0.5)',
              barThickness: 6,
              borderRadius: 2,
              borderWidth: 0.87,
            },
          ],
        },
        options: {
          indexAxis: 'y',
          responsive: false,
          maintainAspectRatio: false,
          borderSkipped: false,
          scales: {
            x: {
              grid: {
                display: false,
              },
              display: false,
            },
            y: {
              grid: {
                display: true,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        },
        plugins: [bulletPointPlugin],
      });
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  const externalTooltipHandler = (context) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set Text
    if (tooltip.body) {
      const { dataPoints } = tooltip;
      const tooltipGraphData = dataPoints?.[0]?.raw?.sourceData;

      tooltipEl.chartInstance.data.labels = tooltipGraphData?.map((data) => {
        return data?.company;
      });
      tooltipEl.chartInstance.data.datasets[0].data = tooltipGraphData?.map(
        (data) => {
          return {
            x: [data?.min, data?.max],
            y: data?.company,
            mid: data?.mid,
            compensationUnit: data?.compensationUnit,
          };
        },
      );
      const minSourceValue = Math.min(
        ...(dataPoints?.[0]?.raw?.sourceData?.map((data) => data?.min) ?? []),
      );
      const maxSourceValue = Math.max(
        ...(dataPoints?.[0]?.raw?.sourceData?.map((data) => data?.max) ?? []),
      );

      tooltipEl.chartInstance.options.scales.x.min =
        minSourceValue - minSourceValue / 10;
      tooltipEl.chartInstance.options.scales.x.max =
        maxSourceValue + maxSourceValue / 10;
      tooltipEl.chartInstance.update();
    }

    // Ensure tooltip is shown after content is set to get correct dimensions
    const tooltipWidth = tooltipEl.offsetWidth;
    const tooltipHeight = tooltipEl.offsetHeight;

    // Center horizontally
    const activeElement = chart.getActiveElements()[0];
    if (!activeElement) {
      return; // No active element, no tooltip positioning
    }

    // Get the bounding box of the active element
    const { x, y, width, height } = activeElement.element.getProps([
      'x',
      'y',
      'width',
      'height',
    ]);

    // Calculate center position of the bar
    const centerX = x - width / 2;
    const centerY = y; // Assuming a vertical bar chart

    // Position tooltip in the center of the bar
    const {
      scrollTop,
      scrollHeight,
      clientHeight,
      scrollLeft,
      scrollWidth,
      clientWidth,
    } = graphRef.current;

    const leftPos =
      chart.canvas.offsetLeft - scrollLeft + centerX - tooltipWidth / 2;
    const topPos =
      chart.canvas.offsetTop - scrollTop + centerY + height / 2 + 2;

    // Apply styles to position the tooltip
    tooltipEl.style.opacity = 1;
    tooltipEl.style.zIndex = 20;
    tooltipEl.style.left = `${leftPos}px`;
    tooltipEl.style.top = `${topPos}px`;
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding =
      tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
  };

  const tooltip = {
    yAlign: 'top',
    enabled: false,
    backgroundColor: 'white',
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 8,
    titleColor: '#475467',
    callbacks: {
      labelTextColor: function () {
        return '#475467'; // Change label text color
      },
    },
    external: externalTooltipHandler,
    position: 'nearest',
  };
  const minValue = Math.min(
    ...(data?.data?.map((barData) => barData?.min) ?? []),
  );
  const maxValue = Math.max(
    ...(data?.data?.map((barData) => barData?.max) ?? []),
  );
  const noOfTicks = 10;

  let minX = minValue - (minValue % noOfTicks);
  let maxX = maxValue + (noOfTicks - (maxValue % noOfTicks));
  let stepSize = (maxX - minX) / noOfTicks;
  if (stepSize % (!isLakhsCompUnit ? 1000 : 100000) !== 0) {
    stepSize =
      stepSize +
      ((!isLakhsCompUnit ? 1000 : 100000) -
        (stepSize % (!isLakhsCompUnit ? 1000 : 100000)));
    minX = minValue - (minValue % stepSize);
    maxX = maxValue + (stepSize - (maxValue % stepSize));
  }

  const graphWidth = 2560;
  const graphHeight = contractDataToGraphDataMapper(data).labels.length * 40;
  const tableHeight = graphHeight + 40 - 9;
  const tableRowHeight =
    tableHeight / (contractDataToGraphDataMapper(data).labels.length + 1);

  const XLabelsDiv = ({ minX, maxX, stepSize, compensationUnit }) => {
    // Generate an array of numbers from minX to maxX with the given step size
    const numbers = [];
    for (let i = minX; i < maxX; i += stepSize) {
      numbers.push(i);
    }

    return (
      <div
        className={`flex w-[${graphWidth}px] h-[30px] bg-white`}
        style={{
          position: 'sticky',
          bottom: 0,
        }}
      >
        {numbers.map((number, index) => (
          <div className="w-full" key={index}>
            {index === numbers.length - 1 ? (
              <div className="flex justify-between">
                <p className="font-figtree text-gray-500 font-medium text-xs">
                  INR{' '}
                  {`${convertCompensationInUnit(number, isLakhsCompUnit)}${
                    compensationUnit ? 'L' : 'K'
                  }`}
                </p>
                <p className="font-figtree text-gray-500 font-medium text-xs">
                  INR{' '}
                  {`${convertCompensationInUnit(
                    number + stepSize,
                    isLakhsCompUnit,
                  )}${compensationUnit ? 'L' : 'K'}`}
                </p>
              </div>
            ) : (
              <p className="font-figtree text-gray-500 font-medium text-xs">
                INR{' '}
                {`${convertCompensationInUnit(number, isLakhsCompUnit)}${
                  compensationUnit ? 'L' : 'K'
                }`}
              </p>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="flex h-full max-h-[363px]">
      <div className="flex overflow-y-auto" ref={graphRef}>
        <div
          style={{
            height: `${graphHeight + 40 - 9 + 45}px`,
            width: '400px',
            paddingBottom: '45px',
            position: 'sticky',
            left: 0,
            top: 0,
            zIndex: 10,
          }}
          className="flex items-baseline place-items-baseline bg-white flex-nowrap"
        >
          <table>
            <thead>
              <tr className="border">
                <th
                  className="text-nowrap px-2 bg-white"
                  style={{
                    position: 'sticky',
                    top: 0,
                    left: 0,
                    height: `${tableRowHeight}px`,
                  }}
                >
                  Pay Range Title
                </th>
                <th
                  className="text-nowrap px-2 bg-white"
                  style={{
                    position: 'sticky',
                    top: 0,
                    left: 0,
                    height: `${tableRowHeight}px`,
                  }}
                >
                  Pay Range Code
                </th>
              </tr>
            </thead>
            <tbody className="">
              {data?.data?.map((data, index) => {
                return (
                  <tr
                    key={index}
                    className=""
                    style={{
                      boxShadow:
                        '4px 0px 4px -2px rgba(16, 24, 40, 0.06), 2px 0px 4px -2px rgba(16, 24, 40, 0.05)',
                    }}
                  >
                    <td
                      className="font-figtree font-semibold text-gray-900 text-sm text-nowrap  border px-2 bg-white"
                      style={{
                        height: `${tableRowHeight}px`,
                      }}
                    >
                      {data?.['Pay Range Title']}
                    </td>
                    <td
                      className={`font-figtree font-semibold text-gray-900 text-sm text-nowrap  border px-2 bg-white ${
                        index === 1 ? '' : ''
                      }`}
                      style={{
                        height: `${tableRowHeight}px`,
                      }}
                    >
                      {data?.['Pay Range Code']}
                    </td>
                  </tr>
                );
              })}
              <tr
                style={{
                  boxShadow:
                    '4px 0px 4px -2px rgba(16, 24, 40, 0.06), 2px 0px 4px -2px white',
                }}
              >
                <td
                  className="bg-white h-[45px]"
                  style={{
                    position: 'sticky',
                    bottom: 0,
                    left: 0,
                    height: `${tableRowHeight}px`,
                  }}
                ></td>
                <td
                  className="bg-white h-[45px]"
                  style={{
                    position: 'sticky',
                    bottom: 0,
                    left: 0,
                    height: `${tableRowHeight}px`,
                  }}
                ></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <div
            className="flex w-full bg-white sticky top-0"
            style={{
              height: `${tableRowHeight}px`,
            }}
          ></div>
          <div
            className=""
            style={{
              height: `${graphHeight}px`,
              width: `${graphWidth}px`,
              display: 'inline-block',
            }}
          >
            <BarGraph
              data={contractDataToGraphDataMapper(data, isLakhsCompUnit)}
              chartOptions={{
                indexAxis: 'y',
                maintainAspectRatio: false,
                responsive: true,
                scales: {
                  x: {
                    grid: {
                      display: true,
                      color: (ctx) => {
                        if (ctx?.index === 0) return;
                        return 'hsla(218, 15%, 65%, 0.25)';
                      },
                    },
                    ticks: {
                      stepSize: stepSize,
                      display: false,
                    },
                    min: minX,
                    max: maxX,
                    beginAtZero: false,
                    border: {
                      display: false,
                    },
                  },
                  y: {
                    grid: {
                      display: true,
                      drawBorder: false,
                    },
                    ticks: {
                      display: false,
                    },
                    border: {
                      display: false,
                    },
                  },
                },
                plugins: {
                  tooltip: tooltip,
                  legend: {
                    display: false,
                  },
                },
              }}
              plugins={[bulletPointPlugin]}
            />
          </div>
          <XLabelsDiv
            minX={minX}
            maxX={maxX}
            stepSize={stepSize}
            compensationUnit={isLakhsCompUnit}
          />
        </div>
      </div>
    </div>
  );
};

export default GraphicalView;
