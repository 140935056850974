import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import DeleteFormIcon from 'assets/svg/deleteFormIcon';
import { VersionHistoryIcon } from 'assets/svg/versionHistory';
import { CheckBoxProgram } from 'components/atoms/FormElements/input/Input';
import { useEffect, useRef, useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import FiltersPopUp from './filtersPopUp/FiltersPopUp';
import '../../../internalRange/rangeTable/rangeTable.scss';

export const RangeTable = ({
  selectedRowId,
  setSelectedRowId,
  setAddRowVisible,
  handleDelete,
  dropDownFilters,
  selectedFilters,
  setSelectedFilters,
  benchmarkWithColSpan,
  subHeadings,
  tableData,
  setIsViewDetailsPopUp,
  setDefaultTabSelected,
  isAddRow,
  setPageSize,
  isRefetchingTableData,
  maxRowCount,
}) => {
  const [collapsedId, setCollapsedId] = useState('');
  const [viewRoleSelectedData, setViewRoleSelectedData] = useState();
  const [filtersPopUp, setFiltersPopUp] = useState();

  const tableContainerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const container = tableContainerRef.current;
      console.log(
        container.scrollHeight,
        container.scrollTop,
        container.clientHeight,
        isRefetchingTableData,
      );
      if (
        !isRefetchingTableData &&
        container.scrollHeight - container.scrollTop - 2 <=
          container.clientHeight
      ) {
        // Refetch data when scrolled to bottom
        setPageSize((pageSize) => {
          return pageSize + 8 < maxRowCount ? pageSize + 8 : pageSize;
        });
      }
    };

    const container = tableContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isRefetchingTableData]);

  const handleSelectedFilter = (option, column) => {
    if (option) {
      if (selectedFilters?.filter((filter) => filter?.key === column)?.length) {
        setSelectedFilters(
          selectedFilters?.map((filter) => {
            if (filter?.key === column) {
              return { ...filter, value: option?.value };
            }
            return filter;
          }),
        );
      } else {
        setSelectedFilters([
          ...selectedFilters,
          { key: column, operator: 'EQUAL', value: option?.value },
        ]);
      }
    } else {
      setSelectedFilters(
        selectedFilters?.filter((filter) => filter?.key !== column),
      );
    }
  };

  const handleRowSelect = (data) => {
    if (selectedRowId?.includes(data?.id)) {
      setSelectedRowId(selectedRowId?.filter((id) => id !== data?.id));
    } else {
      setSelectedRowId([...selectedRowId, data?.id]);
    }
  };

  const handleBulkDelete = () => {
    handleDelete(selectedRowId);
    setSelectedRowId([]);
  };

  return (
    <>
      <div className="space-y-[10px]">
        {selectedRowId?.length > 0 && (
          <div className="flex items center gap-6 px-2">
            <p className="flex items-center gap-3">
              <CheckBoxProgram checked />
              <span className="text-sm font-medium text-gray-900">{`${selectedRowId?.length} selected`}</span>
            </p>
            <p className="text-gray-200">|</p>
            <p
              className="gap-2 flex items-center text-sm font-semibold text-gray-600 cursor-pointer"
              onClick={handleBulkDelete}
            >
              <DeleteFormIcon className="cursor-pointer" />
              Delete
            </p>
          </div>
        )}
        <div className="rangeBuilder" ref={tableContainerRef}>
          <table className="rangeTable w-full">
            <thead className="w-full">
              <tr className="w-full">
                {benchmarkWithColSpan?.map((column) => {
                  return (
                    <th
                      key={column?.id}
                      style={{
                        backgroundColor: column?.colBgCol
                          ? column?.colBgCol
                          : 'gray',
                      }}
                      colSpan={column?.colSpan}
                      onClick={() =>
                        setCollapsedId(
                          collapsedId !== column?.id ? column?.id : '',
                        )
                      }
                    >
                      <div className="flex items-center text-white">
                        {column?.columnName?.length ? (
                          collapsedId !== column?.id ? (
                            <ChevronRightIcon className="w-5 h-5 text-white" />
                          ) : (
                            <ChevronLeftIcon className="w-5 h-5 text-white" />
                          )
                        ) : (
                          <></>
                        )}
                        {column?.columnName}
                      </div>
                    </th>
                  );
                })}
                <th style={{ backgroundColor: '#F9F9F9' }}>
                  {isAddRow && (
                    <div
                      className="flex items-center cursor-pointer justify-center gap-1 text-gray-700 w-full"
                      onClick={() => setAddRowVisible(true)}
                    >
                      <PlusIcon className="w-4 h-4 text-gray-900 font-semibold" />
                      Add Row
                    </div>
                  )}
                </th>
              </tr>
            </thead>
            <tbody className="text-sm text-gray-600">
              <tr>
                {subHeadings?.map((column) => {
                  return (
                    <>
                      <td
                        className="capitalize"
                        style={{
                          color: '#98A2B3',
                          backgroundColor: column?.bgColor,
                          zIndex: 4,
                        }}
                      >
                        <div className="flex items-center justify-between gap-4">
                          <div
                            style={{
                              color: column?.textColor ?? 'gray',
                            }}
                            className="flex w-full justify-between"
                          >
                            <p>{column?.name ?? column}</p>
                            {column?.isFilter && (
                              <div className="w-fit relative z-10">
                                <ChevronDownIcon
                                  className="w-5 h-5 cursor-pointer"
                                  onClick={() =>
                                    setFiltersPopUp((prevValue) =>
                                      prevValue === column?.value
                                        ? null
                                        : column?.value,
                                    )
                                  }
                                />
                                {filtersPopUp === column?.value && (
                                  <FiltersPopUp
                                    options={dropDownFilters?.[
                                      column?.value
                                    ]?.map((item) => ({
                                      label: item,
                                      value: item,
                                    }))}
                                    setFiltersPopUp={setFiltersPopUp}
                                    handleSelectedFilter={handleSelectedFilter}
                                    column={column}
                                  />
                                )}
                                {/* <Select
                              id="remove-border-select"
                              options={dropDownFilters?.[column?.value]?.map(
                                (item) => ({ label: item, value: item }),
                              )}
                              isClearable
                              onChange={(option) =>
                                handleSelectedFilter(option, column?.value)
                              }
                            /> */}
                              </div>
                            )}
                          </div>
                        </div>
                      </td>
                    </>
                  );
                })}
                <td style={{ backgroundColor: '#F9F9F9' }}></td>
              </tr>
              {tableData?.map((data) => {
                return (
                  <tr key={data?.id}>
                    {data?.data?.map((rowData, index) => {
                      return (
                        <>
                          {index === 0 && (
                            <td
                              key={rowData?.id}
                              style={{ zIndex: 2 }}
                              className="shadow-md"
                            >
                              <div className="flex items-center gap-3">
                                <CheckBoxProgram
                                  onChange={() => handleRowSelect(data)}
                                  checked={selectedRowId?.includes(data?.id)}
                                />
                                <p
                                  className="text-sm font-semibold text-gray-900 cursor-pointer hover:font-underline"
                                  onClick={() => {
                                    setDefaultTabSelected(null);
                                    setIsViewDetailsPopUp(data);
                                    setViewRoleSelectedData(data?.data);
                                  }}
                                >
                                  {rowData}
                                </p>
                              </div>
                            </td>
                          )}
                          {index !== 0 && (
                            <td
                              style={{
                                backgroundColor: subHeadings?.[index]?.bgColor,
                              }}
                            >
                              {rowData}
                            </td>
                          )}
                        </>
                      );
                    })}

                    <td className="shadow-md">
                      <div className="flex items-center justify-center px-4 gap-5">
                        <VersionHistoryIcon
                          onClick={() => {
                            setDefaultTabSelected('Version History');
                            setIsViewDetailsPopUp(data);
                          }}
                          className="cursor-pointer"
                        />
                        <DeleteFormIcon
                          onClick={() => handleDelete([data?.id])}
                          className="w-4 h-4 cursor-pointer text-gray-600"
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
